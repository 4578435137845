import { useState, useEffect } from "react"
import { FormGroup, Row, Col, Label, Input } from 'reactstrap';
import Progress from "../../../components/Progress/sca";
import monthsData from '../../../components/date/date.json';
import yearData from '../../../components/date/year.json';
import annualIncome from '../../../components/json/annualIncome.json';
import netWorth from '../../../components/json/netWorth.json';
import jsonData from '../../../components/json/accountsType.json';
import countriesData from "../../../components/json/countriesStates.json";
import { toast } from "react-toastify";
import { activitiesServices, clientService } from "../../../services";
import TokenService from "../../../services/token.service";
import loader from "../../../assets/images/loader.gif";
import IntlTelInput from 'react-intl-tel-input';
import PasswordValidation from "../passwordValidation";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import { PiExclamationMarkDuotone } from "react-icons/pi";
import CryptoJS from "crypto-js";
import { Trans, useTranslation } from 'react-i18next';
import ReCAPTCHA from "react-google-recaptcha";
import useRecaptcha from "../googleRecaptcha/useRecaptcha";

import { Button, CustomForm } from "../style";
import 'react-intl-tel-input/dist/main.css';
import '../style.css';
import '../../../index.css';

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
const decryptData = (ciphertext) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
};

export const Form = ({ entity }) => {
    const [refValue, setRefValue] = useState('');
    const [ptrValue, setPtrValue] = useState('');
    const [filteredAccountTypes, setFilteredAccountTypes] = useState('');
    const [userLogin, setUserLogin] = useState('');
    const [formattedDate, setFormattedDate] = useState('');
    const [currentStep, setCurrentStep] = useState(1);
    const [emailError, setEmailError] = useState(0);
    const [imageList, setImageList] = useState([]);
    const [selectedCountryCode, setSelectedCountryCode] = useState('');
    const [loading, setLoading] = useState(false);
    const [countryList, setCountriesList] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [platForm, setPlatForm] = useState('MT4');
    const [utmMediumValue, setUtmMediumValue] = useState('');
    const [morocco, setMorocco] = useState(false);

    const [singleClick, setSingleClick] = useState(false);
    const [checkEmail, setCheckEmail] = useState(false);
    const [checkBox, setCheckBox] = useState('');
    const [ipInfo, setIpInfo] = useState(null);

    const { capchaToken, recaptchaRef, handleRecaptcha } = useRecaptcha();

    const { t, i18n } = useTranslation();


    useEffect(() => {
        setFilteredAccountTypes(filterAccountTypes('MT4'));
    }, [refValue]);

    useEffect(() => {
        const fetchIPInfo = async () => {
            try {
                const response = await fetch('https://ipinfo.io/json?token=52a7645817b83a');
                const data = await response.json();
                setIpInfo(data);
            } catch (error) {
                console.error('Error fetching IP information:', error);
            }
        };

        fetchIPInfo();
    }, []);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const refParam = searchParams.get('rm');
        const ptrParam = searchParams.get('ptr');
        let ibUrl = window.location.origin + `/?ptr=${ptrParam}&rm=${refParam}`;

        if (ibUrl === `${window.location.origin}/?ptr=2000095497&rm=69`) {
            window.location.href = `https://signup.godocm.com/?entity=FSA_MA&utm_medium=godoweb_ma&ptr=2000095497`;
        }

        if ((ibUrl === `${window.location.origin}/?ptr=2000095497&rm=101`) || (refParam === '101') || (ipInfo !== null && ipInfo.country === 'MA') ) {
            window.location.href = ptrParam ? `https://signup-ma.godocm.com/?ptr=${ptrParam}&rm=101&utm_medium=godoweb_ma` :
            `https://signup-ma.godocm.com/?rm=101&utm_medium=godoweb_ma`;
        }

    }, [ipInfo]);

    useEffect(() => {

        const searchParams = new URLSearchParams(window.location.search);
        const refParam = searchParams.get('rm');
        const ptrParam = searchParams.get('ptr');
        const entityParam = searchParams.get('entity');
        const platform = searchParams.get('platform');
        const utm = searchParams.get('utm_medium');
        const partner = searchParams.get('partner');

        if (entity === 'FSC') {
            const removeCountry = ['AFGHANISTAN', 'CUBA', 'GHANA', 'IRAN', 'LIBYA', 'MYANMAR', 'PANAMA', 'SOUNTH SUDAN', 'SYRIA', 'USA', 'YEMEN'];
            const filterCountry = countriesData.filter((country) => !removeCountry.includes(country.name));
            setCountriesList(filterCountry);
        } else if (entity === 'SCA') {
            const filterCountry = countriesData.filter((country) => country.name === "UNITED ARAB EMIRATES");
            setCountriesList(filterCountry);
        } else {
            setCountriesList(countriesData);
        }

        setFilteredAccountTypes(filterAccountTypes('MT4'));
        // Parse the query parameters from the URL
        // Get the value of the 'ref' parameter
        if (partner === 'Traderscaptain') {
            setRefValue('95');
            setPtrValue('2000111910');
        }

        if (entityParam) {
            setCurrentStep(1);
            formData.step1.country = '';
            formData.step2.idType = '';
            formData.step1.accountType = '';
            formData.step2.modeOfPayment = '';
        }

        // mobile app url
        if (platform === 'MT5' && utm === 'godotrader_app' && entityParam === 'FSA') {
            window.location.href = `https://signup.godocm.com/?entity=FSA_MA&utm_medium=godotrader_app&platform=MT5`;
        }

        if (platform === 'MT5' && utm === 'godotrader_app' && entityParam === 'FSA_MA') {
            setPlatForm('MT5');
            setUtmMediumValue(utm);
            setMorocco(true);
            formData.step1.platform = 'MT5';
            formData.step1.utmMedium = utm;
            setFilteredAccountTypes(filterAccountTypes('MT5'));
            const filterCountry = countriesData.filter((country) => country.name === "MOROCCO");
            setCountriesList(filterCountry);
        }

        if (platform === 'MT5' && utm === 'godotrader_app' && entityParam === 'FSA') {
            setPlatForm('MT5');
            setUtmMediumValue(utm);
            formData.step1.platform = 'MT5';
            formData.step1.utmMedium = utm;
            setFilteredAccountTypes(filterAccountTypes('MT5'));
        }

        //desktop url
        if ((utm === 'godoweb_ma' && !platform)) {
            setUtmMediumValue(utm);
            setMorocco(true);
            formData.step1.utmMedium = utm;
            const filterCountry = countriesData.filter((country) => country.name === "MOROCCO");
            setCountriesList(filterCountry);
        }

        if (platform === 'MT5' && utm === 'godoweb_ma' && entityParam === 'FSA_MA') {
            setPlatForm('MT5');
            setUtmMediumValue(utm);
            setMorocco(true);
            formData.step1.platform = 'MT5';
            formData.step1.utmMedium = utm;
            setFilteredAccountTypes(filterAccountTypes('MT5'));
            const filterCountry = countriesData.filter((country) => country.name === "MOROCCO");
            setCountriesList(filterCountry);
        }

        // Set the value to the state
        if (refParam) {
            setRefValue(refParam);
        }
        if (ptrParam && entityParam !== 'FSA_MA' && !utm) {
            //setEntityValue(true) // seting entity to FSA
            setPtrValue(ptrParam);
        }

        clientService.authorization().then((res) => {
            const token = res.token;
            const decryptToken = decryptData(token);
            TokenService.setUser(decryptToken);
        }).catch((err) => {
            console.log('Error for auth is ', err);
            toast.error("Authorization error!");
        });
    }, [entity, morocco, utmMediumValue, ipInfo]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        const step = name.split('.')[0]; // Extract step number from input name
        const field = name.split('.')[1]; // Extract field name from input name

        if (value !== '') {
            setFormData(prevData => ({
                ...prevData,
                [step]: {
                    ...prevData[step],
                    [field]: value
                }
            }));
        }
    };

    const [formData, setFormData] = useState({
        step1: { agentId: refValue, parentId: ptrValue, entity: entity, callStatus: 'New Lead', firstName: '', email: '', phone: '', accountType: '', accountTypeCurrency: '', password: '', confirmPassword: '', country: '', countryCode: '', platform: 'MT4', utmMedium: utmMediumValue },
        step2: { nationality: '', nationalityCode: '', state: '', address: '', proofOfAddress: '', idType: '', idTypeCode: '', passportIdNo: '', year: '', month: '', day: '', dob: '', proofOfIdentity: '', proofOfIdentityBack: '', residency: '', residencyCode: '', resProofOfAddress: '', annualIncome: '', netWorth: '', modeOfPayment: '', modeOfPaymentCode: '', sourceOfFunds: '', sourceOfFundsCode: '', sourceOfIncome: '', taxResidency: '', taxIdentificationNumber: '', additional: '' },
        step3: { experiencedTrading: false, sharesFrequency: '', cfdFrequency: '', powerOfAttorney: '', learn: true, accountTradedByOthers: false, acceptTradingTerms: true },
        step4: { usCitizen: false, politicallyExposed: false, beneficialOwnerCode: '', declaration: Boolean }
    });

    const data = {
        //step1
        agentId: refValue || '33',
        parentId: ptrValue,
        entity: entity,
        firstName: formData.step1.firstName,
        email: formData.step1.email,
        platform: formData.step1.platform,
        accountType: formData.step1.accountType,
        accountTypeCurrency: formData.step1.accountTypeCurrency,
        country: formData.step1.country,
        countryCode: formData.step1.countryCode,
        phone: formData.step1.phone,
        password: formData.step1.password,
        utmMedium: utmMediumValue || '',

        //step2
        nationality: formData.step2.nationality,
        nationalityCode: formData.step2.nationalityCode,
        dob: formattedDate || (`${formData.step2.year}-${formData.step2.month}-${formData.step2.day}`),
        idType: formData.step2.idType,
        idTypeCode: formData.step2.idTypeCode,
        passportIdNo: formData.step2.passportIdNo,
        proofOfIdentity: formData.step2.proofOfIdentity,
        proofOfIdentityBack: formData.step2.proofOfIdentityBack,
        residency: formData.step2.residency,
        residencyCode: formData.step2.residencyCode,
        resProofOfAddress: formData.step2.resProofOfAddress,
        annualIncome: formData.step2.annualIncome,
        netWorth: formData.step2.netWorth,
        modeOfPayment: formData.step2.modeOfPayment,
        modeOfPaymentCode: formData.step2.modeOfPaymentCode,
        sourceOfFunds: formData.step2.sourceOfFunds,
        sourceOfFundsCode: formData.step2.sourceOfFundsCode,
        sourceOfIncome: formData.step2.sourceOfIncome,
        taxResidency: formData.step2.taxResidency,
        taxIdentificationNumber: formData.step2.taxIdentificationNumber,
        address: formData.step2.address,
        additional: formData.step2.additional,

        //step3
        experiencedTrading: formData.step3.experiencedTrading,
        sharesFrequency: formData.step3.sharesFrequency,
        cfdFrequency: formData.step3.cfdFrequency,
        learn: formData.step3.learn,
        accountTradedByOthers: formData.step3.accountTradedByOthers,
        acceptTradingTerms: formData.step3.acceptTradingTerms,

        //step4
        usCitizen: formData.step4.usCitizen,
        politicallyExposed: formData.step4.politicallyExposed,
        beneficialOwnerCode: formData.step4.beneficialOwnerCode || 'N',
        declaration: formData.step4.declaration,

        accountStatus: 'New',
        isLead: false,
        isClient: true,
    }

    const [validationErrors, setValidationErrors] = useState({
        step1: {
            firstName: false, phone: false, accountType: false, otpValue: false, country: false, countryCode: false, password: false, confirmPassword: false, platform: false
        },
        step2: {
            nationality: false, nationalityCode: false, address: false, proofOfAddress: false, idType: false, idTypeCode: false, passportIdNo: false, dob: false, year: false, month: false, day: false, proofOfIdentity: false, proofOfIdentityBack: false, residency: false, residencyCode: false, resProofOfAddress: false, annualIncome: false, netWorth: false, modeOfPayment: false, modeOfPaymentCode: false, sourceOfFunds: false, sourceOfFundsCode: false, source_Of_Income: false, taxResidency: false, taxIdentificationNumber: false, additional: false
        },
        step3: {
            experiencedTrading: false, sharesFrequency: false, cfdFrequency: false, learn: false, accountTradedByOthers: false, powerOfAttorney: false, acceptTradingTerms: true
        },
        step4: { usCitizen: true, politicallyExposed: true, beneficialOwnerCode: false, declaration: false }
    });

    const formValueChange = (step, name, value) => {
        const englishRegex = /^[a-zA-Z0-9\s.,'?!@#$%^&:*()_+=-]*$/;
        const uploadedFileNames = [
            'proofOfIdentity',
            'proofOfIdentityBack',
            'resProofOfAddress',
            'sourceOfIncome',
            'additional',
            'powerOfAttorney'
        ];

        const updateFormData = (newValue) => {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [step]: {
                    ...prevFormData[step],
                    [name]: newValue,
                },
            }));
        };

        const validateEmail = (email) => {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            setEmailError(emailPattern.test(email) ? 0 : 1);
        };

        const handlePhoneNumber = (phone) => {
            const sanitizedPhone = phone.replace(/[^\d]/g, '');
            updateFormData(sanitizedPhone);
        };

        const handleFormValueChange = () => {
            if (name === 'email') {
                validateEmail(value);
                updateFormData(value);
            } else if (name === 'phone') {
                handlePhoneNumber(value);
            } else if (name === 'dob' || englishRegex.test(value) || uploadedFileNames.includes(name)) {
                updateFormData(value);
            }
        };

        handleFormValueChange();
    };


    const validatePass = (password) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#&$]).{8,12}$/;
        return regex.test(password);
    }

    const validatePassword = (password) => {
        const isValid = validatePass(password);
        if (isValid) {
            return true;
        }
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            step1: {
                ...prevErrors.step1,
                password: !isValid,
            },
        }));
    };


    const isStep1Valid = () => {
        if (!validatePassword(formData.step1.password)) {
            toast.error("Password must be upper, lower and symbols($,&,@,#)");
            return false;
        }
        if (!(formData.step1.password === formData.step1.confirmPassword)) {
            toast.error("Password and confirm password must match");
            return false;
        }
        const { firstName, email, phone, accountType, password, confirmPassword, country } = formData.step1;

        // Check each field individually and set their validity
        const isfirstNameValid = firstName.trim() !== '';
        const isEmailValid = email.trim() !== '';
        const isphoneValid = phone.trim() !== '';
        const isAccountTypeValid = accountType !== '';
        const isPasswordValid = password.trim() !== '';
        const isConfirmPasswordValid = confirmPassword.trim() !== '' && password.trim() === confirmPassword.trim();
        const isCountryValid = country !== '';
        const isNationalityValid = formData.step2.nationality !== '';

        // Set the validation errors dynamically for each field
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            step1: {
                ...prevErrors.step1,
                firstName: !isfirstNameValid,
                email: !isEmailValid,
                phone: !isphoneValid,
                accountType: !isAccountTypeValid,
                password: !isPasswordValid,
                confirmPassword: !isConfirmPasswordValid,
                country: !isCountryValid,
            },
            step2: {
                nationality: !isNationalityValid,
            }
        }));


        // Return true if all fields are valid, false otherwise
        const validTrue = isfirstNameValid && isEmailValid && isNationalityValid && isphoneValid &&
            isAccountTypeValid && isPasswordValid && isConfirmPasswordValid && isCountryValid;
        if (!validTrue) {
            toast.error("Please fill all the fields!");
            return false;
        }
        return true;
    };

    const isStep2Valid = () => {
        const { nationality, address, idType, passportIdNo, year, month, day, proofOfIdentity, proofOfIdentityBack, residency, resProofOfAddress, annualIncome, netWorth, modeOfPayment, sourceOfFunds, source_Of_Income, additional } = formData.step2;
        // Check each field individually and set their validity
        const isNationality = nationality !== '';
        const isAddress = address !== '';
        const isDay = day !== '';
        const isYear = year !== '';
        const isMonth = month !== '';
        const isIdType = idType !== '';
        const isIdNumber = passportIdNo.trim() !== '';
        const isproofOfIdentity = proofOfIdentity !== '';
        const isResidency = residency !== '';
        const isAnnualIncome = annualIncome !== '';
        const isNetWorth = netWorth !== '';
        const isModeOfPayment = modeOfPayment !== '';
        const issourceOfFunds = sourceOfFunds !== '';

        let testResult = true;

        // Check if any uploaded files are the same
        const uploadedFiles = [proofOfIdentity, proofOfIdentityBack, resProofOfAddress, source_Of_Income, additional];

        if (proofOfIdentity === '') {
            testResult = false; // Return false if any of the fields is empty
            toast.error('Please upload all required files');
        } else {
            for (let i = 0; i < uploadedFiles.length; i++) {
                for (let j = i + 1; j < uploadedFiles.length; j++) {
                    if (uploadedFiles[i] && uploadedFiles[i] === uploadedFiles[j]) {
                        toast.error(`Uploaded files could not be the same`);
                        return testResult = false;
                    }
                }
            }

        }

        // Set the validation errors dynamically for each field
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            step2: {
                ...prevErrors.step2,
                address: !isAddress,
                annualIncome: !isAnnualIncome,
                passportIdNo: !isIdNumber,
                idType: !isIdType,
                modeOfPayment: !isModeOfPayment,
                netWorth: !isNetWorth,
                residency: !isResidency,
                proofOfIdentity: !isproofOfIdentity,
                year: !isYear,
                month: !isMonth,
                day: !isDay,
                sourceOfFunds: !issourceOfFunds,
            },
        }));

        if (!morocco ? isNationality && isYear && isMonth && isDay && isIdType && isIdNumber && isAddress && isproofOfIdentity && testResult
            && isResidency && isAnnualIncome && isNetWorth && isModeOfPayment && issourceOfFunds : isNationality && isYear && isMonth && isDay && isIdType && isIdNumber && isAddress && isproofOfIdentity && testResult
        && isResidency) {
            return true;
        }
        else {
            toast.error("Fill all the required fields!");
            return false;
        }
    };

    const isStep3Valid = () => {
        const { experiencedTrading, accountTradedByOthers, acceptTradingTerms, sharesFrequency, cfdFrequency, powerOfAttorney } = formData.step3;

        const isExperiencedTrading = experiencedTrading === true || false;
        const isAccountTradedByOthers = accountTradedByOthers === true || false;
        const isPowerOfAttorney = powerOfAttorney !== '';
        const isSharesFrequency = sharesFrequency !== '';
        const isCfdFrequency = cfdFrequency !== '';
        const isAcceptTradingTerms = acceptTradingTerms === true || false;

        // Set the validation errors dynamically for each field
        if (isExperiencedTrading) {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                step3: {
                    sharesFrequency: !isSharesFrequency,
                    cfdFrequency: !isCfdFrequency,
                    powerOfAttorney: !powerOfAttorney,
                }
            }));
        }

        if ((isExperiencedTrading ? isExperiencedTrading && isCfdFrequency && isSharesFrequency : !isExperiencedTrading) &&
            (isAccountTradedByOthers ? isAccountTradedByOthers && isPowerOfAttorney : !isAccountTradedByOthers) &&
            isAcceptTradingTerms) {
            return true;
        } else {
            return false;
        }
    };


    const isStep4Valid = () => {
        const { taxIdentificationNumber } = formData.step2;
        const { usCitizen, politicallyExposed } = formData.step4;
        const isUsCitizen = usCitizen === true || false;
        const isPep = politicallyExposed === true || false;
        const isTaxIdentificationNumber = taxIdentificationNumber !== '';

        // Set the validation errors dynamically for each field
        if (isUsCitizen) {
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                step2: {
                    taxIdentificationNumber: !isTaxIdentificationNumber,
                }
            }));
        }

        if ((isUsCitizen ? isUsCitizen && isTaxIdentificationNumber : !isUsCitizen) && (isPep ? isPep : !isPep)) {
            return true;
        } else {
            if (isUsCitizen) {
                toast.error("Fill all the required fields!");
            }
            return false;
        }
    };

    function formatDate(inputDate) {
        // Create a new Date object by parsing the input date string
        const date = new Date(inputDate);

        // Get the year, month, and day from the parsed date
        const year = date.getFullYear();
        // Month needs to be converted to a two-digit format
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        // Day also needs to be converted to a two-digit format
        const day = ('0' + date.getDate()).slice(-2);

        // Format the date and time as 'YYYY-MM-DD 4:00:00'
        const formattedDate = `${year}-${month}-${day} 4:00:00`;

        return formattedDate;
    }

    const scrollToTopButton = () => {
        window.scrollTo({ top: 100, behavior: 'smooth' });
    }

    const submitForm = async (e, action) => {
        e.preventDefault();

        if (!checkBox) {
            setCheckBox(false);
        }

        if (action === 'next') {
            if (currentStep === 1) {
                const step1Data = { ...formData.step1, entity: entity, agentId: refValue || '33', parentId: ptrValue, nationality: formData.step2.nationality, nationalityCode: formData.step2.nationalityCode, utmMedium: formData.step1.utmMedium };
                if (isStep1Valid() && checkBox === true) {

                    // Remove the confirmPassword field
                    delete step1Data.confirmPassword;
                    step1Data.phone = phoneNumber !== undefined ? phoneNumber + step1Data.phone.slice(1) : step1Data.phone.slice(1);
                    //setCurrentStep(currentStep + 1);

                    if (checkEmail) {
                        setCurrentStep(currentStep + 1);
                    } else {
                        clientService.postClient(step1Data)
                            .then(async (res) => {
                                console.log('res for backend: ', res);
                                if (res && res.data && res.data.message) {
                                    toast.error(res.data.message); // Display the error message
                                    setLoading(false);
                                } else {
                                    toast.success('User Created Successfully!');
                                    setCurrentStep(currentStep + 1);
                                    setCheckEmail(true);
                                    setUserLogin(res);
                                    setRefValue(res?.agentId);
                                    setPtrValue(res?.parentId);

                                    const logData = {
                                        type: 'userRegister',
                                        customerId: res.id,
                                        note: ipInfo && JSON.stringify(ipInfo) + ` urlLink=${window.location.href}`
                                    }
                    
                                    await activitiesServices.postActivity(logData);
                                }

                            })
                            .catch(() => {
                                toast.error('An error occurred while processing your request'); // Default error message
                                setLoading(false);
                            });
                        scrollToTopButton();
                    }

                } else {
                    setLoading(false);
                }
            }
            if (currentStep === 2) {

                const year = formData.step2.year;
                const month = formData.step2.month;
                const day = formData.step2.day;

                // Check if all required date components are present
                if (year && month && day) {
                    // Construct the date string in YYYY-MM-DD format
                    const dateString = `${year}-${month}-${day}`;

                    // Format the date string
                    const formattedDateString = formatDate(dateString);

                    // Set the formatted date string to the state
                    setFormattedDate(formattedDateString);
                } else {
                    // If any of the required date components is missing, set the default date string
                    const defaultDateString = '1970-01-01 4:00:00';
                    setFormattedDate(defaultDateString);
                }

                if (isStep2Valid()) {
                    setCurrentStep(currentStep + 1);
                    scrollToTopButton();
                } else {
                    const date = formData.step2.year + '-' + formData.step2.month + '-' + formData.step2.day;
                    const formattedDateString = formatDate(date);
                    setLoading(false);

                    // Set the formatted date string to the state
                    setFormattedDate(formattedDateString);

                }
            }
            if (currentStep === 3) {
                if (isStep3Valid()) {
                    setCurrentStep(currentStep + 1);
                    scrollToTopButton();
                    recaptchaRef.current.execute();
                } else {
                    toast.error("Fill all the required fields!");
                    setLoading(false);
                }
            }
        } else if (action === 'previous') {
            setCurrentStep(currentStep - 1);
            scrollToTopButton();
        } else {
            if (isStep4Valid()) {

                if (!singleClick) {
                    setSingleClick(true);
                    const formDataValue = new FormData();
                    formDataValue.append('customerId', userLogin.id);
                    imageList.forEach((item) => {
                        formDataValue.append(item.key, item.value[0]); // Assuming each item contains only one file
                    });

                    try {
                        // recaptch token
                            // Upload documents
                            const uploadResponse = await clientService.uploadDocuments(formDataValue);
                            if (uploadResponse && uploadResponse.length !== 0) {

                                // Set loading state
                                setLoading(true);

                                data.acceptedTerms = true;
                                data.acceptedRisk = true;
                                data.acceptedPrivacy = true;
                                data.acceptedExecutionPolicy = true;
                                data.acceptedConflicts = true;

                                try {
                                    // Update client information
                                    const updateResponse = await clientService.updateClient(userLogin.id, data);
                                    if (updateResponse && updateResponse.login) {

                                        // Authorize CP
                                        const convertIntoString = updateResponse.login.toString();
                                        const authResponse = await clientService.authorizationCp(convertIntoString, formData.step1.password);
                                        if (authResponse && authResponse.token) {
                                            // Get the token
                                            const token = authResponse.token;
                                            setLoading(false);

                                            // Construct the redirect URL with the token as a query parameter
                                            const redirectURL = `https://login.godocm.com/silent-login?_token=${encodeURIComponent(token)}`;

                                            // Redirect the user to the constructed URL
                                            window.location.href = redirectURL;

                                            toast.success('Successfully Registered!');
                                            scrollToTopButton();
                                        } else {
                                            setSingleClick(false);
                                            recaptchaRef.current?.reset();
                                            throw new Error('Authorization failed');
                                        }
                                    }
                                } catch (updateError) {
                                    setLoading(false);
                                    setSingleClick(false);
                                    recaptchaRef.current?.reset();
                                    toast.error('Account Registration failed, Contact support!');
                                    console.log('Error updating client:', updateError);
                                }

                            } else {
                                setSingleClick(false);
                                throw new Error('Document upload failed');
                            }

                    } catch (Error) {
                        setLoading(false);
                        setSingleClick(false);
                        toast.error('reCAPTCHA validation failed!');
                        recaptchaRef.current.execute();
                        console.log('error: ', Error);
                    }

                }    

            } else {
                setLoading(false);
                toast.error("Fill all the required fields!");
            }
        }
    };

    // const onChange = async (value) => {

    //     //Now, use the obtained token to make the postClient request
    //     const formDataValue = new FormData();
    //     formDataValue.append('customerId', userLogin.id);
    //     imageList.forEach((item) => {
    //         formDataValue.append(item.key, item.value[0]); // Assuming each item contains only one file
    //     });

    //     try {
    //         // Upload documents
    //         const uploadResponse = await clientService.uploadDocuments(formDataValue);
    //         // Validate upload response
    //         if (uploadResponse && uploadResponse.length !== 0) {

    //             // Set loading state
    //             setLoading(true);

    //             //Validate and update data
    //             if (data) {
    //                 data.acceptedTerms = true;
    //                 data.acceptedRisk = true;
    //                 data.acceptedPrivacy = true;
    //                 data.acceptedExecutionPolicy = true;
    //                 data.acceptedConflicts = true;
    //                 data.token = value;

    //                 try {
    //                     // Update client information
    //                     const updateResponse = await clientService.updateClient(userLogin.id, data);
    //                     if (updateResponse && updateResponse.login) {

    //                         // Authorize CP
    //                         const convertIntoString = updateResponse.login.toString();
    //                         const authResponse = await clientService.authorizationCp(convertIntoString, formData.step1.password);
    //                         if (authResponse && authResponse.token) {
    //                             // Get the token
    //                             const token = authResponse.token;
    //                             setLoading(false);

    //                             // Construct the redirect URL with the token as a query parameter
    //                             const redirectURL = `https://login.godocm.com/silent-login?_token=${encodeURIComponent(token)}`;

    //                             // Redirect the user to the constructed URL
    //                             window.location.href = redirectURL;

    //                             toast.success('Successfully Registered!');
    //                             scrollToTopButton();
    //                         } else {
    //                             throw new Error('Authorization failed');
    //                         }
    //                     } else {
    //                         toast.error('Update client failed!');
    //                         throw new Error('Update client failed');
    //                     }
    //                 } catch (updateError) {
    //                     setLoading(false);
    //                     toast.error('Account Registration failed, Contact support!');
    //                     console.log('Error updating client:', updateError);
    //                     console.error('Error updating client:', updateError);
    //                 }
    //             } else {
    //                 throw new Error('Data validation failed');
    //             }
    //         } else {
    //             throw new Error('Document upload failed');
    //         }
    //     } catch (uploadError) {
    //         setLoading(false);
    //         toast.error('Upload documents again');
    //         console.log('Error uploading documents:', uploadError.message);
    //     }

    //     console.log("Captcha value:", value);
    // }

    const updateListFunction = (key, files) => {

        // Check if the key already exists in the imageList
        const existingIndex = imageList.findIndex(entry => entry.key === key);

        if (existingIndex !== -1) {
            // If the key already exists, update its value
            const updatedList = [...imageList];
            updatedList[existingIndex].value = files;
            setImageList(updatedList);

        } else {
            // If the key does not exist, add it as a new entry
            const newValue = {
                key,
                value: files
            };

            const updatedList = [...imageList, newValue];
            setImageList(updatedList);
        }
    };

    const filterAccountTypes = (platform) => {
        if (ptrValue && refValue !== 38 && !morocco) {
            return jsonData.filter(item => (item['TP platform'] === platform && item['ib'] === 1))
        } else if (ptrValue && refValue !== 38 && morocco) {
            return jsonData.filter(item => (item['TP platform'] === platform && item['ib'] === 3))
        } else if (refValue === 38) {
            return jsonData.filter(item => (item['TP platform'] === platform && item['Server Number'] === 2))
        } else if (morocco) {
            return jsonData.filter(item => (item['TP platform'] === platform && item['Server Number'] === 3))
        } else {
            return jsonData.filter(item => (item['TP platform'] === platform && item['Server Number'] === 1))
        }
    };

    const accountTypeValue = (platform) => {
        // Determine accountType based on platform
        const accountType = morocco === false ? platform === 'MT5' ? '2' : '14' : platform === 'MT5' ? '42' : '38';

        // Update form data
        setFormData((prevFormData) => ({
            ...prevFormData,
            step1: {
                ...prevFormData.step1,
                accountType,
                accountTypeCurrency: 'USD'
            },
        }));

    }

    return (
        <div style={{ overflow: "hidden" }}>
            {/* <div id='recaptcha-container' style={{ display: 'block' }}></div> */}
            {loading ?
                <div id="global-loader1">
                    <img src={loader} className="loader-img" alt="Loading...." />
                </div> : <>
                    <div className="flex-center py-10 RSPBprogressBars d-sms-none">
                        <Progress currentStep={currentStep} />
                    </div>
                    <CustomForm style={{ fontSize: `${(i18n.language === 'de' || i18n.language === 'fa') ? '18px' : ''}` }}>
                        {currentStep === 1 &&
                            <div className="justify">
                                <Row className={`${(i18n.language === 'de' || i18n.language === 'fa') ? 'flex-row-reverse text-end' : ''}`}>
                                    <Col md={4} style={{ display: 'none' }}>
                                        <FormGroup>
                                            <Label>Ibid</Label>
                                            <div className="input-group">
                                                <Input className={"form-control"}
                                                    name="agentId" type="text"
                                                    required="" placeholder="John"
                                                    value={refValue}
                                                    onChange={(e) => formValueChange('step1', 'agentId', refValue)}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4} style={{ display: 'none' }}>
                                        <FormGroup>
                                            <Label>Entity</Label>
                                            <div className="input-group">
                                                <Input className={"form-control"}
                                                    name="entity" type="text"
                                                    required="" placeholder="John"
                                                    value={entity}
                                                    onChange={(e) => formValueChange('step1', 'entity', entity)}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label>{t('description.part10')}</Label>
                                            <div className="input-group">
                                                <Input className={"form-control " + ((!validationErrors.step1.firstName || formData.step1.firstName) ? 'is-valid' : 'is-invalid')}
                                                    name="firstName"
                                                    type="text"
                                                    required=""
                                                    placeholder="John"
                                                    value={formData.step1.firstName}
                                                    onChange={(e) => {
                                                        // Remove non-alphanumeric characters except spaces
                                                        let cleanedInput = e.target.value.replace(/[^a-zA-Z0-9 ]/g, '');

                                                        // Split the input into words, capitalize the first letter of each word, and join them back
                                                        let capitalizedInput = cleanedInput.split(' ')
                                                            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                            .join(' ');

                                                        // Update the form value
                                                        formValueChange('step1', 'firstName', capitalizedInput);
                                                    }}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col className="mobileView" md={12}>
                                        <Label style={{ color: 'gray' }}>{t('description.part13')}</Label>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label>{t('description.part11')}</Label>
                                            <div className="input-group">
                                                <Input className={"form-control " + (!validationErrors.step1.email || formData.step1.email ? 'is-valid' : 'is-invalid')}
                                                    type="email"
                                                    name="email"
                                                    disabled={checkEmail ? true : false}
                                                    validation={{ required: true }}
                                                    value={formData.step1.email}
                                                    invalid={(emailError === 1 && formData.step1.email !== '' && 1) || validationErrors.step1.email}
                                                    valid={(emailError === 0 && formData.step1.email !== '' && 1) || !validationErrors.step1.email}
                                                    placeholder="john@gmail.com"
                                                    onChange={(e) => formValueChange('step1', e.target.name, e.target.value)}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <Label>{t('description.part12')}</Label>
                                        <Input
                                            className={"mb-3 " + (!validationErrors.step2.nationality || formData.step2.nationality ? 'is-valid' : 'is-invalid')}
                                            type="select"
                                            name="nationality"
                                            onChange={(e) => {
                                                const selectedCountryName = e.target.value; // Get the selected country name
                                                const selectedCountry = countriesData.find(country => country.name === selectedCountryName); // Find the country object based on the name
                                                if (selectedCountry) {

                                                    formValueChange('step2', 'nationality', selectedCountry.name);
                                                    formValueChange('step2', 'nationalityCode', selectedCountry.iso2);

                                                }
                                            }}
                                            value={formData.step2.nationality} // Set the value to the selected country name
                                        >
                                            <option value="">-- Select --</option>
                                            {countriesData.map((item, index) => (
                                                <option key={index} value={item.name}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </Input>
                                    </Col>
                                    <Col className="DesktopView" md={12}>
                                        <Label style={{ color: 'gray' }}>{t('description.part13')}</Label>
                                    </Col>
                                    <Col md={4} style={{ display: 'none' }}>
                                        <FormGroup>
                                            <Label>Middle Name</Label>
                                            <div className="input-group">
                                                <Input className={"form-control " + ((!validationErrors.step1.middleName || formData.step1.middleName) ? 'is-valid' : 'is-invalid')}
                                                    name="middleName" type="text"
                                                    required="" placeholder="Reo"
                                                    value={formData.step1.middleName}
                                                    onChange={(e) => formValueChange('step1', 'middleName', e.target.value)}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4} style={{ display: 'none' }}>
                                        <FormGroup>
                                            <Label>Last Name</Label>
                                            <div className="input-group">
                                                <Input className={"form-control " + ((!validationErrors.step1.lastName || formData.step1.lastName) ? 'is-valid' : 'is-invalid')}
                                                    type="text"
                                                    name="lastName"
                                                    value={formData.step1.lastName}
                                                    required="" placeholder="Doe"
                                                    onChange={(e) => formValueChange('step1', 'lastName', e.target.value)}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className={`${(i18n.language === 'de' || i18n.language === 'fa') ? 'flex-row-reverse text-end' : ''}`} >
                                    <Col md={12}>
                                        <Row className={`${(i18n.language === 'de' || i18n.language === 'fa') ? 'flex-row-reverse text-end' : ''}`}>
                                            <Col md={8}>
                                                <Row className={`${(i18n.language === 'de' || i18n.language === 'fa') ? 'flex-row-reverse text-end' : ''}`}>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label>{t('description.part14')}</Label>
                                                            <div className="input-group">
                                                                <Input
                                                                    type="select"
                                                                    name="platform"
                                                                    className={formData.step1.platform ? "is-valid" : "is-invalid"}
                                                                    value={formData.step1.platform}
                                                                    onChange={(e) => {
                                                                        formValueChange('step1', e.target.name, e.target.value);
                                                                        setFilteredAccountTypes(filterAccountTypes(e.target.value));
                                                                        accountTypeValue(e.target.value);
                                                                    }}
                                                                >
                                                                    {platForm === 'MT4' && <option value="MT4">MT4</option>}
                                                                    <option value="MT5">MT5</option>
                                                                    <option value="CT">CTrader</option>
                                                                </Input>
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label>{t('description.part15')}</Label>
                                                            <div className="input-group">
                                                                <Input
                                                                    type="select"
                                                                    name="accountType"
                                                                    className={!validationErrors.step1.accountType || formData.step1.accountType ? 'is-valid' : 'is-invalid'}
                                                                    value={formData.step1.accountType}
                                                                    onChange={(e) => {
                                                                        if (e.target.value !== '') {
                                                                            formValueChange('step1', e.target.name, e.target.value);
                                                                            const accountCurrency = filteredAccountTypes.filter((account) => account.Id === parseInt(e.target.value));
                                                                            formValueChange('step1', 'accountTypeCurrency', accountCurrency[0].Currency);
                                                                        }
                                                                    }}
                                                                >
                                                                    <option value="">-- Select --</option>
                                                                    {filteredAccountTypes && filteredAccountTypes.map((item, index) => (
                                                                        <option key={index} value={item['Id']}>
                                                                            {item['Account type']}
                                                                        </option>
                                                                    ))}
                                                                </Input>
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6} className="position-relative">
                                                        <FormGroup>
                                                            <Label>{t('description.part16')}</Label>
                                                            <Input
                                                                type={`${showPassword ? "text" : "password"}`}
                                                                name="password"
                                                                className={"form-control required input_password " + (validationErrors.step1.password ? 'is-invalid' : 'is-valid')}
                                                                placeholder="***********"
                                                                minLength={8}
                                                                maxLength={12}
                                                                value={formData.step1.password}
                                                                onChange={(e) => {
                                                                    const { name, value } = e.target;
                                                                    if (value.length < 13) {
                                                                        formValueChange('step1', name, value);
                                                                    }
                                                                }}
                                                            />
                                                            {showPassword ? <FaRegEye onClick={() => setShowPassword(false)} className="position-absolute" style={{ top: "43px", right: "49px" }} /> : <FaRegEyeSlash onClick={() => setShowPassword(true)} className="position-absolute" style={{ top: "43px", right: "49px" }} />}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6} className="position-relative">
                                                        <FormGroup>
                                                            <Label>{t('description.part17')}</Label>
                                                            <Input
                                                                type={`${showPassword ? "text" : "password"}`}
                                                                name="confirmPassword"
                                                                className={"form-control required input_password " + (validationErrors.step1.confirmPassword ? 'is-invalid' : 'is-valid')}
                                                                placeholder="***********"
                                                                minLength={8}
                                                                maxLength={12}
                                                                value={formData.step1.confirmPassword}
                                                                onChange={(e) => {
                                                                    const { name, value } = e.target;
                                                                    if (value.length < 13) {
                                                                        formValueChange('step1', name, value);
                                                                    }
                                                                }}
                                                            />
                                                            {showPassword ? <FaRegEye onClick={() => setShowPassword(false)} className="position-absolute" style={{ top: "43px", right: "49px" }} /> : <FaRegEyeSlash onClick={() => setShowPassword(true)} className="position-absolute" style={{ top: "43px", right: "49px" }} />}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label>{t('description.part18')}</Label>
                                                            <Input
                                                                className={"mb-3 " + (!validationErrors.step1.country || formData.step1.country ? 'is-valid' : 'is-invalid')}
                                                                type="select"
                                                                name="country"
                                                                onChange={(e) => {
                                                                    const selectedCountryName = e.target.value;
                                                                    const selectedCountry = countriesData.find(country => country.name === selectedCountryName);
                                                                    if (selectedCountry) {
                                                                        setSelectedCountryCode(selectedCountry.iso2.toLowerCase());
                                                                        formValueChange('step1', 'country', selectedCountry.name);
                                                                        formValueChange('step1', 'countryCode', selectedCountry.iso2);
                                                                        formValueChange('step2', 'residency', selectedCountry.name);
                                                                        formValueChange('step2', 'residencyCode', selectedCountry.iso2);
                                                                    }
                                                                }}
                                                                value={formData.step1.country}
                                                            >
                                                                <option value="">-- Select --</option>
                                                                {countryList.map((item, index) => (
                                                                    <option key={index} value={item.name}>
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6} className="phoneNum">
                                                        <Label>{t('description.part19')}</Label>
                                                        <IntlTelInput
                                                            name="phone"
                                                            containerClassName="intl-tel-input"
                                                            inputClassName={"form-control " + ((!validationErrors.step1.phone || formData.step1.phone) ? 'is-valid' : 'is-invalid')}
                                                            style={{ width: '100%' }}
                                                            maxLength={15}
                                                            format="true"
                                                            autoFormat={false}
                                                            separateDialCode={false}
                                                            disabled={checkEmail ? true : false}
                                                            defaultCountry={selectedCountryCode || 'ae'}
                                                            useMobileFullscreenDropdown={false}
                                                            value={formData.step1.phone}
                                                            onPhoneNumberChange={(status, value, countryData, number, isValidNumberPrecise, id, fullNumber) => {
                                                                if (value.length < 15) {
                                                                    setPhoneNumber(countryData.dialCode);
                                                                    formValueChange('step1', 'phone', value);
                                                                }
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <PasswordValidation password={formData.step1.password} />
                                                </FormGroup>
                                            </Col>
                                            {/* <Col md={12}>
                                                <Row>
                                                    <Col md={4} className="phoneNum">
                                                        <Label>Phone No</Label>
                                                        <IntlTelInput
                                                            name="phone"
                                                            containerClassName="intl-tel-input"
                                                            inputClassName={"form-control " + (check && !formData.step1.phone && "is-invalid")}
                                                            style={{ width: '100%' }}
                                                            maxLength={15}
                                                            format="true"
                                                            disabled={numberVerify ? true : false}
                                                            defaultCountry={selectedCountryCode || 'ae'}
                                                            useMobileFullscreenDropdown={false}
                                                            value={formData.step1.phone}
                                                            onSelectFlag={(selectedCountryData, fullNumber, isvalid, currentNumber) => {
                                                                setCountryid(fullNumber && fullNumber.iso2 && fullNumber.iso2.toUpperCase());
                                                            }}
                                                            onPhoneNumberChange={(status, value, countryData, number, id) => {
                                                                let formattedPhoneNumber = "+" + countryData.dialCode + value.slice(1).replace(/\s+/g, '');
                                                                setPhoneNumber(formattedPhoneNumber);
                                                                formValueChange('step1', 'phone', value);
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col> */}
                                            <Col md={12}>
                                                <div className={`input-group mb-3 mt-4 d-flex align-items-center ${checkBox === '' || checkBox === true ? 'text-primary' : 'text-danger'} ${(i18n.language === 'de' || i18n.language === 'fa') ? 'flex-row-reverse text-end' : ''}`}>
                                                    <label style={{ direction: (i18n.language === 'de' || i18n.language === 'fa') && 'rtl' }} className={`d-flex align-items-center ${(i18n.language === 'de' || i18n.language === 'fa') ? ' text-end' : ''}`}>
                                                        <input
                                                            type="checkbox"
                                                            name="correctInfo"
                                                            className={`${(i18n.language === 'de' || i18n.language === 'fa') ? 'ms-3' : ''}`}
                                                            checked={Boolean(checkBox)}
                                                            onChange={() => {
                                                                setCheckBox(!checkBox)
                                                            }}
                                                        />
                                                        &nbsp;&nbsp;&nbsp; {t('description.part27')}
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        }
                        {currentStep === 2 &&
                            <div className="justify w_100" style={{ margin: "0px" }}>
                                <Row className={`${(i18n.language === 'de' || i18n.language === 'fa') ? 'flex-row-reverse text-end' : ''}`}>
                                    <Col md={12} className="mt-3"><h5>{t('description.part32')}</h5></Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label>{t('description.part33')}</Label>
                                            <Input
                                                className={"mb-3 "}
                                                type="text"
                                                name="residency"
                                                disabled // Disable the input
                                                value={formData.step2.residency || formData.step1.country}
                                            >
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <Label>{t('description.part34')}</Label>
                                        <div className="input-group mb-3">
                                            <Input
                                                className={"form-control " + ((!validationErrors.step2.address || formData.step2.address) ? 'is-valid' : 'is-invalid')}
                                                name="address" type="text"
                                                value={formData.step2.address}
                                                required=""
                                                onChange={(e) => formValueChange('step2', e.target.name, e.target.value)}
                                                placeholder="House No - Street - City - State" />
                                        </div>
                                    </Col>
                                    <Col md={4} style={{ display: 'none' }}>
                                        <Label for="proofOfAddress">
                                            Upload Document (Proof Of Address)
                                            <div className="tooltip" style={{ opacity: '1', position: 'relative', top: '-3px' }}><PiExclamationMarkDuotone style={{ rotate: '180deg' }} k />
                                                <span className="tooltiptext">Please provide at least ONE of the following documents that
                                                    MUST state <b>current or permanent residential address as per Application form</b> and have been issued within the last 3 calendar months
                                                    <ul>
                                                        <li>Utility Bills or statements from public utilities, including electricity, water, gas, or telephone line providers  </li>
                                                        <li>Lease / Tenancy Agreement  (MUST be within the lease term) </li>
                                                        <li>Local and national government-issued documents, including municipal tax records or similar</li>
                                                        <li>Registered property purchase, lease or rental agreements</li>
                                                        <li>Documents from supervised third-party financial institutions, such as bank statements or insurance policies, alike.</li>
                                                        <li>Incase the client is sharing a bedspace or on a short term rent, may provide permenant residence document of his/her nationality.</li>
                                                    </ul>
                                                </span>
                                            </div>
                                        </Label>
                                        <Input
                                            className={"mb-3 "}
                                            type="file"
                                            name="proofOfAddress"
                                            accept=".jpg, .jpeg, .png, .pdf"
                                            onChange={(e) => {
                                                updateListFunction('PROOF_OF_ADDRESS', [e.target.files[0]]);
                                                formValueChange('step2', e.target.name, e.target.value);
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row className={`${(i18n.language === 'de' || i18n.language === 'fa') ? 'flex-row-reverse text-end' : ''}`}>
                                    <Col md={4}>
                                        <Label>{t('description.part35')}</Label>
                                        {formData.step2.residency !== 'UNITED ARAB EMIRATES' ?
                                            <div className="input-group mb-3">
                                                <select
                                                    className={"form-control " + ((!validationErrors.step2.idType || formData.step2.idType) ? 'is-valid' : 'is-invalid')}
                                                    name="idType"
                                                    value={formData.step2.idType}
                                                    required=""
                                                    onChange={
                                                        (e) => {
                                                            if (e.target.value !== '') {
                                                                formValueChange('step2', e.target.name, e.target.value)
                                                                if (e.target.value === 'EID-EMIRATES ID') {
                                                                    formValueChange('step2', 'idTypeCode', 'EID')
                                                                } else if (e.target.value === 'PP-PASSPORT') {
                                                                    formValueChange('step2', 'idTypeCode', 'PP')
                                                                } else if (e.target.value === 'NID-NATIONAL ID') {
                                                                    formValueChange('step2', 'idTypeCode', 'NID')
                                                                } else if (e.target.value === 'DL-DRIVING LICENSE') {
                                                                    formValueChange('step2', 'idTypeCode', 'DL')
                                                                } else if (e.target.value === 'RESIDENT PERMIT CARD') {
                                                                    formValueChange('step2', 'idTypeCode', 'RPC')
                                                                }
                                                            }
                                                        }}>
                                                    <option value="">-- Select ID Type --</option>
                                                    <option value="EID-EMIRATES ID">EID-EMIRATES ID</option>
                                                    <option value="PP-PASSPORT">PP-PASSPORT</option>
                                                    <option value="NID-NATIONAL ID">NID-NATIONAL ID</option>
                                                    <option value="DL-DRIVING LICENSE">DL-DRIVING LICENSE</option>
                                                    <option value="RESIDENT PERMIT CARD">RESIDENT PERMIT CARD</option>
                                                </select>
                                            </div> :
                                            <div className="input-group mb-3">
                                                <select
                                                    className={"form-control " + ((!validationErrors.step2.idType || formData.step2.idType) ? 'is-valid' : 'is-invalid')}
                                                    name="idType"
                                                    value={formData.step2.idType}
                                                    required=""
                                                    onChange={(e) => {
                                                        if (e.target.value !== '') {
                                                            formValueChange('step2', e.target.name, e.target.value);
                                                            formValueChange('step2', 'idTypeCode', 'EID')
                                                        }
                                                    }}>
                                                    <option value="">-- Select ID Type --</option>
                                                    <option value="EID-EMIRATES ID">EID-EMIRATES ID</option>
                                                </select>
                                            </div>
                                        }
                                    </Col>
                                    <Col md={4}>
                                        <Label>{t('description.part36')}</Label>
                                        <div className="input-group mb-3">
                                            <Input
                                                className={"form-control " + ((!validationErrors.step2.passportIdNo || formData.step2.passportIdNo) ? 'is-valid' : 'is-invalid')}
                                                name="passportIdNo" type="text"
                                                value={formData.step2.passportIdNo}
                                                required=""
                                                onChange={(e) => formValueChange('step2', e.target.name, e.target.value)}
                                                placeholder="ID Number" />
                                        </div>
                                    </Col>
                                    <Col md={4} style={{ display: "none" }}>
                                        <Label>ID Issued Place</Label>
                                        <div className="input-group mb-3">
                                            <Input
                                                className={"form-control "}
                                                // className={"form-control " + (check && !formData.step2.idIssuedPlace && 'is-invalid')}
                                                name="idIssuedPlace"
                                                type="text"
                                                // value={nationality}
                                                required=""
                                                disabled // Disable the input
                                                placeholder="UAE" />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className={`${(i18n.language === 'de' || i18n.language === 'fa') ? 'flex-row-reverse text-end' : ''}`}>
                                    <Col md={4}>
                                        <Label for="proofOfIdentity" className="text-primary fw-bold">{t('description.part37')}
                                            {i18n.language !== ('de' || 'fa') && <div className="tooltip" style={{ opacity: '1', position: 'relative', top: '-3px' }}><PiExclamationMarkDuotone style={{ rotate: '180deg' }} />
                                                <span className="tooltiptext">Please provide at least <b>ONE</b> of the following documents that
                                                    MUST have legal name, Not expired, date of birth, nationality, & registered ID number:
                                                    <ul>
                                                        <li>Passport - For any Nationality </li>
                                                        <li>Emirates ID, in case the client is UAE Resident </li>
                                                        <li>Any other Government Issued ID which has these credentials</li>
                                                    </ul>
                                                </span>
                                            </div>}
                                        </Label>
                                        <Input
                                            className={"mb-1 " + ((!validationErrors.step2.proofOfIdentity || formData.step2.proofOfIdentity) ? 'is-valid' : 'is-invalid')}
                                            type="file"
                                            name="proofOfIdentity"
                                            id="proofOfIdentity"
                                            accept=".jpg, .jpeg, .png, .pdf, .heic"
                                            onChange={(e) => {
                                                const maxFileSize = 5 * 1024 * 1024; // 5 MB in bytes

                                                const fileExtension = ['jpg', 'jpeg', 'png', 'pdf', 'heic'];
                                                const fileName = e.target.value;
                                                const file = e.target.files[0];
                                                const fileExtensionCheck = fileName.split('.').pop().toLowerCase();

                                                if (file) {
                                                    if (file.size > maxFileSize) {
                                                        alert('File size exceeds the limit of 5 MB.');
                                                        e.target.value = '';
                                                        formValueChange('step2', e.target.name, e.target.value);
                                                    } else if (fileExtension.includes(fileExtensionCheck)) {
                                                        updateListFunction('PROOF_OF_ID', [e.target.files[0]]);
                                                        formValueChange('step2', e.target.name, e.target.value);
                                                    } else {
                                                        alert('Please Upload files with extension jpg, jpeg, png, pdf, heic');
                                                        e.target.value = '';
                                                        formValueChange('step2', e.target.name, e.target.value);
                                                    }
                                                }
                                            }}
                                        />
                                        {formData.step2.proofOfIdentity && <span className="fs-6">SelectedFile: {formData.step2.proofOfIdentity.slice(12, 24)}</span>}
                                    </Col>
                                    <Col md={4}>
                                        <Label for="proofOfIdentityBack" className="text-primary fw-bold">{t('description.part38')}
                                        </Label>
                                        <Input
                                            className={"mb-1 "}
                                            type="file"
                                            name="proofOfIdentityBack"
                                            id="proofOfIdentityBack"
                                            accept=".jpg, .jpeg, .png, .pdf, .heic"
                                            onChange={(e) => {
                                                const maxFileSize = 5 * 1024 * 1024; // 5 MB in bytes

                                                const fileExtension = ['jpg', 'jpeg', 'png', 'pdf', 'heic'];
                                                const fileName = e.target.value;
                                                const file = e.target.files[0];
                                                const fileExtensionCheck = fileName.split('.').pop().toLowerCase();

                                                if (file) {
                                                    if (file.size > maxFileSize) {
                                                        alert('File size exceeds the limit of 5 MB.');
                                                        e.target.value = '';
                                                        formValueChange('step2', e.target.name, e.target.value);
                                                    } else if (fileExtension.includes(fileExtensionCheck)) {
                                                        updateListFunction('PROOF_OF_ID_BACK', [e.target.files[0]]);
                                                        formValueChange('step2', e.target.name, e.target.value);
                                                    } else {
                                                        alert('Please Upload files with extension jpg, jpeg, png, pdf, heic');
                                                        e.target.value = '';
                                                        formValueChange('step2', e.target.name, e.target.value);
                                                    }
                                                }
                                            }}
                                        />
                                        {formData.step2.proofOfIdentityBack && <span className="fs-6">SelectedFile: {formData.step2.proofOfIdentityBack.slice(12, 24)}</span>}
                                    </Col>
                                    <Col md={4}>
                                        <Label className="text-primary fw-bold">{t('description.part39')}
                                            {i18n.language !== ('de' || 'fa') && <div className="tooltip" style={{ opacity: '1', zIndex: '5', position: 'relative', top: '-3px' }}><PiExclamationMarkDuotone style={{ rotate: '180deg' }} />
                                                <span className="tooltiptextRight">Please provide at least ONE of the following documents that
                                                    MUST state <b>current or permanent residential address as per Application form</b> and have been issued within the last 3 calendar months
                                                    <ul>
                                                        <li>Utility Bills or statements from public utilities, including electricity, water, gas, or telephone line providers  </li>
                                                        <li>Lease / Tenancy Agreement  (MUST be within the lease term) </li>
                                                        <li>Local and national government-issued documents, including municipal tax records or similar</li>
                                                        <li>Registered property purchase, lease or rental agreements</li>
                                                        <li>Documents from supervised third-party financial institutions, such as bank statements or insurance policies, alike.</li>
                                                        <li>Incase the client is sharing a bedspace or on a short term rent, may provide permenant residence document of his/her nationality.</li>
                                                    </ul>
                                                </span>
                                            </div>}
                                        </Label>
                                        <div className="input-group mb-1">
                                            <Input
                                                className={"form-control"}
                                                name="resProofOfAddress"
                                                type="file"
                                                accept=".jpg, .jpeg, .png, .pdf, .heic"
                                                onChange={(e) => {

                                                    const maxFileSize = 5 * 1024 * 1024; // 5 MB in bytes

                                                    const fileExtension = ['jpg', 'jpeg', 'png', 'pdf', 'heic'];
                                                    const fileName = e.target.value;
                                                    const file = e.target.files[0];
                                                    const fileExtensionCheck = fileName.split('.').pop().toLowerCase();

                                                    if (file) {
                                                        if (file.size > maxFileSize) {
                                                            alert('File size exceeds the limit of 5 MB.');
                                                            e.target.value = '';
                                                            formValueChange('step2', e.target.name, e.target.value);
                                                        } else if (fileExtension.includes(fileExtensionCheck)) {
                                                            updateListFunction('RES_PROOF_OF_ADDRESS', [e.target.files[0]]);
                                                            formValueChange('step2', e.target.name, e.target.value);
                                                        } else {
                                                            alert('Please Upload files with extension jpg, jpeg, png, pdf, heic');
                                                            e.target.value = '';
                                                            formValueChange('step2', e.target.name, e.target.value);
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                        {formData.step2.resProofOfAddress && <span className="fs-6">SelectedFile: {formData.step2.resProofOfAddress.slice(12, 24)}</span>}
                                    </Col>
                                </Row>
                                <Row className={`${(i18n.language === 'de' || i18n.language === 'fa') ? 'flex-row-reverse text-end' : ''}`}>
                                    <Col md={12} className="mt-4">
                                        <strong>{t('description.part40')}</strong>
                                    </Col>
                                    <Col md={4}>
                                        <Label>{t('description.part41')}</Label>
                                        <Input
                                            className={"mb-3 " + ((!validationErrors.step2.year || formData.step2.year) ? 'is-valid' : 'is-invalid')}
                                            name="year"
                                            type="select"
                                            value={formData.step2.year}
                                            onChange={(e) => {
                                                if (e.target.value !== '') {
                                                    formValueChange('step2', e.target.name, e.target.value)
                                                }
                                            }}
                                        >
                                            <option value="">Select Year</option>
                                            {Object.keys(yearData).reverse().map((key) => (
                                                <option key={key} value={yearData[key].year}>{yearData[key].year}</option>
                                            ))}
                                        </Input>
                                    </Col>
                                    <Col md={4}>
                                        <Label>{t('description.part42')}</Label>
                                        <Input
                                            className={"mb-3 " + ((!validationErrors.step2.month || formData.step2.month) ? 'is-valid' : 'is-invalid')}
                                            name="month"
                                            type="select"
                                            value={formData.step2.month}
                                            onChange={(e) => {
                                                if (e.target.value !== '') {
                                                    formValueChange('step2', e.target.name, e.target.value)
                                                }
                                            }}
                                        >
                                            <option value="">Select Month</option>
                                            {Object.keys(monthsData).map((key) => (
                                                <option key={key} value={key}>{monthsData[key].name}</option>
                                            ))}
                                        </Input>
                                    </Col>
                                    <Col md={4}>
                                        <Label>{t('description.part43')}</Label>
                                        <Input
                                            className={"mb-3 " + ((!validationErrors.step2.day || formData.step2.day) ? 'is-valid' : 'is-invalid')}
                                            name="day"
                                            type="select"
                                            value={formData.step2.day}
                                            onChange={(e) => {
                                                if (e.target.value !== '') {
                                                    formValueChange('step2', e.target.name, e.target.value)
                                                }
                                            }}
                                        >
                                            <option value="">Select Day</option>
                                            {formData.step2.month && [...Array(monthsData[formData.step2.month].days).keys()].map((day) => (
                                                <option key={day + 1} value={day + 1}>
                                                    {String(day + 1).padStart(2, '0')}
                                                </option>
                                            ))}
                                        </Input>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} style={{ display: "none" }} className="mt-3 flex-wrap align-items-center gap-3">
                                        <h5>Resident Status</h5>
                                        <FormGroup check style={{ paddingBottom: '0.1rem' }}>
                                            <Label check>
                                                <Input
                                                    type="checkbox"
                                                    checked={formData.step2.sameAsAbove}
                                                />
                                                {' '} Same as above
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {!morocco && <Row className={`${(i18n.language === 'de' || i18n.language === 'fa') ? 'flex-row-reverse text-end' : ''}`}>
                                    <Col md={12} className="mt-3"><h5>{t('description.part44')}</h5></Col>
                                    <Col md={4}>
                                        <Label>{t('description.part45')}</Label>
                                        <Input
                                            className={"mb-3 " + ((!validationErrors.step2.annualIncome || formData.step2.annualIncome) ? 'is-valid' : 'is-invalid')}
                                            name="step2.annualIncome"
                                            type="select"
                                            value={formData.step2.annualIncome}
                                            onChange={handleChange}
                                        >
                                            {Object.keys(annualIncome.options).map((key) => (
                                                <option key={key} value={annualIncome.options[key].value}>{annualIncome.options[key].label}</option>
                                            ))}
                                        </Input>
                                    </Col>
                                    <Col md={4}>
                                        <Label>{t('description.part46')}</Label>
                                        <Input
                                            className={"mb-3 " + ((!validationErrors.step2.netWorth || formData.step2.netWorth) ? 'is-valid' : 'is-invalid')}
                                            name="step2.netWorth"
                                            type="select"
                                            value={formData.step2.netWorth}
                                            onChange={handleChange}
                                        >
                                            {Object.keys(netWorth.options).map((key) => (
                                                <option key={key} value={netWorth.options[key].value}>{netWorth.options[key].label}</option>
                                            ))}
                                        </Input>
                                    </Col>
                                    <Col md={4}>
                                        <Label>{t('description.part47')}</Label>
                                        <div className="input-group mb-3">
                                            <Input
                                                className={"mb-3 " + ((!validationErrors.step2.modeOfPayment || formData.step2.modeOfPayment) ? 'is-valid' : 'is-invalid')}
                                                type="select"
                                                name="modeOfPayment"
                                                value={formData.step2.modeOfPayment}
                                                required=""
                                                onChange={(e) => {
                                                    if (e.target.value !== '') {
                                                        formValueChange('step2', e.target.name, e.target.value)
                                                        if (e.target.value === 'Bank Wire') {
                                                            formValueChange('step2', 'modeOfPaymentCode', 'BW')
                                                        } else if (e.target.value === 'Online') {
                                                            formValueChange('step2', 'modeOfPaymentCode', 'ON')
                                                        } else {
                                                            formValueChange('step2', 'modeOfPaymentCode', 'CY')
                                                        }
                                                    }
                                                }}
                                            >
                                                <option value="">-- Select --</option>
                                                <option value="Bank Wire">Bank Wire</option>
                                                <option value="Online">Online</option>
                                                {entity === 'FSA' && <option value="Crypto">Crypto</option>}
                                            </Input>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <Label>{t('description.part48')}</Label>
                                        <div className="input-group mb-3">
                                            <Input
                                                className={"mb-3 " + ((!validationErrors.step2.sourceOfFunds || formData.step2.sourceOfFunds) ? 'is-valid' : 'is-invalid')}
                                                type="select"
                                                name="sourceOfFunds"
                                                value={formData.step2.sourceOfFunds}
                                                required=""
                                                onChange={(e) => {
                                                    if (e.target.value !== '') {
                                                        formValueChange('step2', e.target.name, e.target.value)
                                                        if (e.target.value === 'Salaried') {
                                                            formValueChange('step2', 'sourceOfFundsCode', 'SAL')
                                                        } else if (e.target.value === 'Self-Employed') {
                                                            formValueChange('step2', 'sourceOfFundsCode', 'SLF')
                                                        } else if (e.target.value === 'Freelancer') {
                                                            formValueChange('step2', 'sourceOfFundsCode', 'FRE')
                                                        } else if (e.target.value === 'Inheritance') {
                                                            formValueChange('step2', 'sourceOfFundsCode', 'INH')
                                                        } else {
                                                            formValueChange('step2', 'sourceOfFundsCode', 'UNE')
                                                        }
                                                    }
                                                }}
                                            >
                                                <option value="">-- Select --</option>
                                                <option value="Salaried">Salaried</option>
                                                <option value="Self-Employed">Self-Employed</option>
                                                <option value="Freelancer">Freelancer</option>
                                                <option value="Inheritance">Inheritance</option>
                                                <option value="Unemployed">Unemployed (Housewife, Student etc)</option>
                                            </Input>
                                        </div>
                                    </Col>

                                    <Col md={4}>
                                        <Label for="source_Of_Income" className="text-primary fw-bold">{t('description.part49')}
                                            {i18n.language !== ('de' || 'fa') && <div className="tooltip" style={{ opacity: '1', position: 'relative', top: '-3px' }}><PiExclamationMarkDuotone style={{ rotate: '180deg' }} />
                                                <span className="tooltiptext">Please provide  at least ONE  of the following documents that
                                                    Must reflect source of income trail and transaction for last 3 Calender Months
                                                    <ul>
                                                        <li>Salary - Payslip or Bank Statement (Salaried Person) </li>
                                                        <li>Self-Employeed - Any earning certificate Bank Statement of Company and document showing the relationship (Businessman) </li>
                                                        <li>Husband's/ Guardian's bank statement (In case of Housewife, student or not employed anywhere)</li>
                                                        <li>Any other document reflecting the possession of income reflecting source of income by client including but not limited to Savings, investments, gratuity, pension, documents.</li>
                                                    </ul>
                                                </span>
                                            </div>}
                                        </Label>
                                        <Input
                                            className={"mb-1 "}
                                            type="file"
                                            name="sourceOfIncome"
                                            accept=".jpg, .jpeg, .png, .pdf, .heic"
                                            onChange={(e) => {

                                                const maxFileSize = 5 * 1024 * 1024; // 5 MB in bytes

                                                const fileExtension = ['jpg', 'jpeg', 'png', 'pdf', 'heic'];
                                                const fileName = e.target.value;
                                                const file = e.target.files[0];
                                                const fileExtensionCheck = fileName.split('.').pop().toLowerCase();

                                                if (file) {
                                                    if (file.size > maxFileSize) {
                                                        alert('File size exceeds the limit of 5 MB.');
                                                        e.target.value = '';
                                                        formValueChange('step2', e.target.name, e.target.value);
                                                    } else if (fileExtension.includes(fileExtensionCheck)) {
                                                        updateListFunction('SOURCE_OF_INCOME', [e.target.files[0]]);
                                                        formValueChange('step2', e.target.name, e.target.value);
                                                    } else {
                                                        alert('Please Upload files with extension jpg, jpeg, png, pdf, heic');
                                                        e.target.value = '';
                                                        formValueChange('step2', e.target.name, e.target.value);
                                                    }
                                                }
                                            }}
                                        />
                                        {formData.step2.sourceOfIncome && <span className="fs-6">SelectedFile: {formData.step2.sourceOfIncome.slice(12, 24)}</span>}
                                    </Col>
                                </Row>}

                                <Row style={{ display: "none" }}>
                                    <Col md={12} className="mt-3"><h5>Tax Information</h5></Col>
                                    <Col md={4}>
                                        <Label>Tax Residency:</Label>
                                        <div className="input-group mb-3">
                                            <Input
                                                className={"form-control "}
                                                type="select"
                                                name="taxResidency"
                                                value={formData.step2.taxResidency}
                                                required=""
                                                onChange={(e) => formValueChange('step2', e.target.name, e.target.value)}
                                            >
                                                <option value="">-- Select Country --</option>
                                                {countriesData.map((country, index) => (
                                                    <option key={index} value={country.name}>
                                                        {country.name}
                                                    </option>
                                                ))}
                                            </Input>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <Label>TIN:</Label>
                                        <div className="input-group mb-3">
                                            <Input
                                                className={"form-control "}
                                                name="taxIdentificationNumber"
                                                type="text" // Keep this as text to handle the custom validation
                                                value={formData.step2.taxIdentificationNumber}
                                                required=""
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    // Ensure only numeric values are set
                                                    if (/^\d*$/.test(value)) {
                                                        formValueChange('step2', e.target.name, value);
                                                    }
                                                }}
                                                placeholder="TIN"
                                            />
                                        </div>
                                    </Col>


                                </Row>
                                {!morocco && <Row className={`${(i18n.language === 'de' || i18n.language === 'fa') ? 'flex-row-reverse text-end' : ''}`}>
                                    <Col md={4}>
                                        <Label for="additional" className={`w-100 d-flex align-items-center mt-3 text-primary fw-bold ${(i18n.language === 'de' || i18n.language === 'fa') ? 'justify-conten-end' : ''}`}><h5 className={`${(i18n.language === 'de' || i18n.language === 'fa') ? 'w-100' : ''}`}>{t('description.part50')}</h5>
                                            {i18n.language !== ('de' || 'fa') && <div className="tooltip" style={{ opacity: '1', position: 'relative', top: '-3px' }}><PiExclamationMarkDuotone style={{ rotate: '180deg' }} />
                                                <span className="tooltiptext">Any Other Supporting Document</span></div>}
                                        </Label>
                                        <Input
                                            className={"mb-1 optionalInput w-100 "}
                                            // className={"mb-3 " + (check && !formData.step2.additional && "is-invalid")}
                                            type="file"
                                            name="additional"
                                            accept=".jpg, .jpeg, .png, .pdf, .heic"
                                            onChange={(e) => {

                                                const maxFileSize = 5 * 1024 * 1024; // 5 MB in bytes

                                                const fileExtension = ['jpg', 'jpeg', 'png', 'pdf', 'heic'];
                                                const fileName = e.target.value;
                                                const file = e.target.files[0];
                                                const fileExtensionCheck = fileName.split('.').pop().toLowerCase();

                                                if (file) {
                                                    if (file.size > maxFileSize) {
                                                        alert('File size exceeds the limit of 5 MB.');
                                                        e.target.value = '';
                                                        formValueChange('step2', e.target.name, e.target.value);
                                                    } else if (fileExtension.includes(fileExtensionCheck)) {
                                                        updateListFunction('ADDITIONAL', [e.target.files[0]]);
                                                        formValueChange('step2', e.target.name, e.target.value);
                                                    } else {
                                                        alert('Please Upload files with extension jpg, jpeg, png, pdf, heic');
                                                        e.target.value = '';
                                                        formValueChange('step2', e.target.name, e.target.value);
                                                    }
                                                }
                                            }}
                                        />
                                        {formData.step2.additional && <span className="fs-6">SelectedFile: {formData.step2.additional.slice(12, 24)}</span>}
                                    </Col>
                                </Row>}
                            </div>
                        }
                        {currentStep === 3 &&
                            <div className="justify w_100">
                                <Row className={`${(i18n.language === 'de' || i18n.language === 'fa') ? 'flex-row-reverse text-end' : ''}`}>
                                    <Col md={4}>
                                        <Label>{t('description.part52')}</Label>
                                        <div className={`input-group mb-3 ${(i18n.language === 'de' || i18n.language === 'fa') ? 'align-items-end justify-content-end' : ''} `}>
                                            <div className="form-check form-check-inline">
                                                <Input className="form-check-input"
                                                    type="radio"
                                                    name="experiencedTrading"
                                                    id="experiencedTradingYes"
                                                    value="Yes"
                                                    checked={formData.step3.experiencedTrading === true}
                                                    onChange={(e) => formValueChange('step3', e.target.name, true)} />
                                                <Label className="form-check-label" htmlFor="experiencedTradingYes">{t('description.part53')}</Label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <Input className="form-check-input"
                                                    type="radio"
                                                    name="experiencedTrading"
                                                    id="experiencedTradingNo"
                                                    value="No"
                                                    checked={formData.step3.experiencedTrading === false}
                                                    onChange={(e) => formValueChange('step3', e.target.name, false)} />
                                                <Label className="form-check-label" htmlFor="experiencedTradingNo">{t('description.part54')}</Label>
                                            </div>
                                        </div>
                                    </Col>
                                    {formData.step3.experiencedTrading === true && (
                                        <>
                                            <Col md={4}>
                                                <Label>{t('description.part78')}</Label>
                                                <div className="input-group mb-3">
                                                    <Input
                                                        className={"form-control " + ((!validationErrors.step3.sharesFrequency || formData.step3.sharesFrequency) ? 'is-valid' : 'is-invalid')}
                                                        type="select"
                                                        name="sharesFrequency"
                                                        value={formData.step3.sharesFrequency}
                                                        onChange={(e) => formValueChange('step3', e.target.name, e.target.value)}
                                                    >
                                                        <option value="">-- Select Product --</option>
                                                        <option value="Shares, Bonds, Equities, ETFs">Shares, Bonds, Equities, ETFs</option>
                                                        <option value="Derivatives (Future, Options, Swaps)">Derivatives (Future, Options, Swaps)</option>
                                                        <option value="Forex or Rolling Spot (FX)">Forex or Rolling Spot (FX)</option>
                                                        <option value="CFDs">CFDs</option>
                                                    </Input>
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <Label>{t('description.part79')}</Label>
                                                <div className="input-group mb-3">
                                                    <Input className={"form-control " + ((!validationErrors.step3.cfdFrequency || formData.step3.cfdFrequency) ? 'is-valid' : 'is-invalid')}
                                                        name="cfdFrequency"
                                                        type="select"
                                                        value={formData.step3.cfdFrequency}
                                                        required=""
                                                        onChange={(e) => formValueChange('step3', e.target.name, e.target.value)}
                                                    >
                                                        <option value="">-- Select Product --</option>
                                                        <option value="Rarely: 1 to 25 Trades A Year">Rarely: 1 to 25 Trades A Year</option>
                                                        <option value="Sometime: 25 to 100 Trades A Year">Sometime: 25 to 100 Trades A Year</option>
                                                        <option value="Often: Over a 100 Trades A Year">Often: Over a 100 Trades A Year</option>
                                                    </Input>
                                                </div>
                                            </Col>
                                        </>
                                    )}
                                    {formData.step3.experiencedTrading === false && (
                                        <Col md={12} className={`${(i18n.language === 'de' || i18n.language === 'fa') ? ' d-flex flex-row-reverse text-end' : ''}`}>
                                            <div className={`form-check ${(i18n.language === 'de' || i18n.language === 'fa') ? ' d-flex flex-row-reverse justify-content-end' : ''}`}>
                                                <Input
                                                    className={`form-check-input ${(i18n.language === 'de' || i18n.language === 'fa') ? 'ms-3' : ''}`}
                                                    type="checkbox"
                                                    name="learn"
                                                    id="wantToLearn"
                                                    checked={!formData.step3.experiencedTrading ? formData.step3.learn : false}
                                                    onChange={(e) => formValueChange('step3', e.target.name, e.target.checked)} />
                                                <Label className="form-check-label" htmlFor="wantToLearn">
                                                    {t('description.part55')}
                                                </Label>
                                            </div>
                                        </Col>
                                    )}
                                </Row>
                                <Row className={`${(i18n.language === 'de' || i18n.language === 'fa') ? 'flex-row-reverse text-end' : ''}`}>
                                    <Col md={4}>
                                        <Label>{t('description.part56')}</Label>
                                        <div className={`input-group mb-3 ${(i18n.language === 'de' || i18n.language === 'fa') ? 'align-items-end justify-content-end' : ''} `}>
                                            <div className="form-check form-check-inline">
                                                <Input className="form-check-input"
                                                    type="radio"
                                                    name="accountTradedByOthers"
                                                    id="accountTradedByOthersYes"
                                                    value="Yes"
                                                    checked={formData.step3.accountTradedByOthers === true}
                                                    onChange={(e) => formValueChange('step3', e.target.name, true)} />
                                                <Label className="form-check-label" htmlFor="accountTradedByOthersYes">{t('description.part53')}</Label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <Input className="form-check-input"
                                                    type="radio"
                                                    name="accountTradedByOthers"
                                                    id="accountTradedByOthersNo"
                                                    value="No"
                                                    checked={formData.step3.accountTradedByOthers === false}
                                                    onChange={(e) => formValueChange('step3', e.target.name, false)} />
                                                <Label className="form-check-label" htmlFor="accountTradedByOthersNo">{t('description.part54')}</Label>
                                            </div>
                                        </div>
                                    </Col>
                                    {formData.step3.accountTradedByOthers === true && (
                                        <Col md={4}>
                                            <Label>{t('description.part80')} ( <a href="https://www.godocm.com/limited-power-of-attorney-account-manager/" target="_blank" rel="noopener noreferrer">Link</a>) </Label>
                                            <div className="input-group mb-3">
                                                <Input className={"form-control-file " + ((!validationErrors.step3.powerOfAttorney || formData.step3.powerOfAttorney) ? 'is-valid' : 'is-invalid')}
                                                    name="powerOfAttorney"
                                                    type="file"
                                                    accept=".jpg, .jpeg, .png, .pdf, .heic"
                                                    onChange={(e) => {
                                                        const maxFileSize = 5 * 1024 * 1024; // 5 MB in bytes

                                                        const fileExtension = ['jpg', 'jpeg', 'png', 'pdf', 'heic'];
                                                        const fileName = e.target.value;
                                                        const file = e.target.files[0];
                                                        const fileExtensionCheck = fileName.split('.').pop().toLowerCase();

                                                        if (file) {
                                                            if (file.size > maxFileSize) {
                                                                alert('File size exceeds the limit of 5 MB.');
                                                                e.target.value = '';
                                                                formValueChange('step3', e.target.name, e.target.value);
                                                            } else if (fileExtension.includes(fileExtensionCheck)) {
                                                                updateListFunction('POWER_OF_ATTORNEY', [e.target.files[0]]);
                                                                formValueChange('step3', e.target.name, e.target.value);
                                                            } else {
                                                                alert('Please Upload files with extension jpg, jpeg, png, pdf, heic');
                                                                e.target.value = '';
                                                                formValueChange('step3', e.target.name, e.target.value);
                                                            }
                                                        }
                                                    }
                                                    }
                                                />
                                            </div>
                                            {formData.step3.powerOfAttorney && <span className="fs-6">SelectedFile: {formData.step3.powerOfAttorney.slice(12, 24)}</span>}
                                        </Col>
                                    )}
                                </Row>
                                <Row className={`${(i18n.language === 'de' || i18n.language === 'fa') ? 'flex-row-reverse text-end' : ''}`}>
                                    <Col md={4} className={`d-flex align-items-center ${(i18n.language === 'de' || i18n.language === 'fa') ? 'flex-row-reverse text-end' : 'justify-content-start'} `}>
                                        <div className="input-group mb-3 w-auto">
                                            <div className="form-check form-check-inline mt-2 me-1">
                                                <Input className="form-check-input"
                                                    type="checkbox"
                                                    name="acceptTradingTerms"
                                                    id="acceptTradingTermsYes"
                                                    value="Yes"
                                                    checked={formData.step3.acceptTradingTerms === true}
                                                    onChange={(e) => formValueChange('step3', e.target.name, true)} />
                                            </div>
                                        </div>
                                        <Label>{t('description.part57')}</Label>
                                    </Col>
                                </Row>
                            </div>
                        }
                        {currentStep === 4 && (
                            <div className="justify w_100">
                                <Row className={`${(i18n.language === 'de' || i18n.language === 'fa') ? 'flex-row-reverse text-end' : ''}`}>
                                    <Col md={4} style={{ direction: (i18n.language === 'de' || i18n.language === 'fa') && 'rtl' }}>
                                        <Label><h6><b>FATCA:</b></h6>{t('description.part58')}</Label>
                                        <div className={`input-group mb-3`}>
                                            <div className="form-check form-check-inline">
                                                <Input className="form-check-input"
                                                    type="radio"
                                                    name="usCitizen"
                                                    id="usCitizenYes"
                                                    value="Yes"
                                                    checked={formData.step4.usCitizen === true}
                                                    onChange={(e) => formValueChange('step4', e.target.name, true)} />
                                                <Label className="form-check-label" htmlFor="usCitizenYes">{t('description.part53')}</Label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <Input className="form-check-input"
                                                    type="radio"
                                                    name="usCitizen"
                                                    id="usCitizenNo"
                                                    value="No"
                                                    checked={formData.step4.usCitizen === false}
                                                    onChange={(e) => formValueChange('step4', e.target.name, false)} />
                                                <Label className="form-check-label" htmlFor="usCitizenNo">{t('description.part54')}</Label>
                                            </div>
                                        </div>
                                    </Col>
                                    {formData.step4.usCitizen === true && (
                                        <Col md={4}>
                                            <Label>{t('description.part81')}</Label>
                                            <div className={`input-group mb-3 ${(i18n.language === 'de' || i18n.language === 'fa') ? 'align-items-end justify-content-end' : ''} `}>
                                                <Input
                                                    className={"form-control " + ((!validationErrors.step2.taxIdentificationNumber || formData.step2.taxIdentificationNumber) ? 'is-valid' : 'is-invalid')}
                                                    name="taxIdentificationNumber"
                                                    type="text"
                                                    value={formData.step2.taxIdentificationNumber}
                                                    required=""
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        if (/^\d*$/.test(value)) { // Only allow numbers
                                                            formValueChange('step2', e.target.name, value);
                                                        }
                                                    }}
                                                    placeholder="Enter TIN"
                                                />
                                            </div>
                                        </Col>
                                    )}
                                </Row>
                                <Row className={`${(i18n.language === 'de' || i18n.language === 'fa') ? 'flex-row-reverse text-end' : ''}`}>
                                    <Col md={4} style={{ direction: (i18n.language === 'de' || i18n.language === 'fa') && 'rtl' }}>
                                        <Label><h6><b>{t('description.part59')}</b>
                                            {i18n.language !== ('de' || 'fa') && <div className="tooltip" style={{ opacity: '1', position: 'relative', top: '-3px' }}><PiExclamationMarkDuotone style={{ rotate: '180deg' }} />
                                                <span className="tooltiptextPEP">
                                                    PEP are Natural persons who are or have been entrusted with prominent public functions in the State or any other foreign country such as Heads of States or Governments, senior politicians, senior government officials, judicial or military officials, senior executive managers of state-owned corporations, and senior officials of political parties and persons who are, or have previously been, entrusted with the management of an international organisation or any prominent function within such an organisation; and also includes:
                                                     Direct family members (of the PEP, who are spouses, children, spouses of children, parents).
                                                     Associates known to be close to the PEP, which include:
                                                    ‒ Individuals having joint ownership rights in a legal person or arrangement or any other close Business Relationship with the PEP.
                                                    ‒ Individuals having individual ownership rights in a legal person or arrangement established in favour of the PEP.
                                                </span>
                                            </div>}
                                        </h6>
                                            {t('description.part60')}
                                        </Label>
                                        <div className={`input-group mb-3`}>
                                            <div className="form-check form-check-inline">
                                                <Input className="form-check-input"
                                                    type="radio"
                                                    name="politicallyExposed"
                                                    id="pepYes"
                                                    value="Yes"
                                                    checked={formData.step4.politicallyExposed === true}
                                                    onChange={(e) => { formValueChange('step4', e.target.name, true); formValueChange('step4', 'beneficialOwnerCode', 'Y') }} />
                                                <Label className="form-check-label" htmlFor="pepYes">{t('description.part53')}</Label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <Input className="form-check-input"
                                                    type="radio"
                                                    name="politicallyExposed"
                                                    id="pepNo"
                                                    value="No"
                                                    checked={formData.step4.politicallyExposed === false}
                                                    onChange={(e) => { formValueChange('step4', e.target.name, false); formValueChange('step4', 'beneficialOwnerCode', 'N') }} />
                                                <Label className="form-check-label" htmlFor="pepNo">{t('description.part54')}</Label>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={12} style={{ direction: (i18n.language === 'de' || i18n.language === 'fa') && 'rtl' }}>
                                        <h6>
                                            {t('description.part61')}
                                        </h6>
                                        <p>{t('description.part62')}</p>
                                        <Col md={12}>
                                            <div className={`input-group mb-3 ${(i18n.language === 'de' || i18n.language === 'fa') ? 'align-items-end' : ''} `}>
                                                <label className={`d-flex align-items-center`}>
                                                    <input
                                                        type="checkbox"
                                                        name="declaration"
                                                        className={`${(i18n.language === 'de' || i18n.language === 'fa') ? 'ms-3' : ''}`}
                                                        checked={true}
                                                        onChange={(e) => {
                                                            formValueChange('step4', e.target.name, true)
                                                            //setDeclaration(!declaration)
                                                        }}
                                                    />
                                                    &nbsp;&nbsp;&nbsp; {t('description.part63')}
                                                </label>
                                            </div>
                                        </Col>
                                        <ul>
                                            <li className={`linkList ${(i18n.language === 'de' || i18n.language === 'fa') ? '' : ''}`}>
                                                <p style={{ margin: "0px" }}>{t('description.part64')}
                                                </p>
                                                <a href="https://www.godocm.com/wp-content/uploads/2024/01/Website-Terms-Conditions.pdf" target="_blank" rel="noopener noreferrer"  >{t('description.part65')}</a>
                                                <a href="https://www.godocm.com/wp-content/uploads/2024/01/Client-Complaint-Policy.pdf" target="_blank" rel="noopener noreferrer" >{t('description.part66')}</a>
                                                <a href="https://www.godocm.com/wp-content/uploads/2024/01/Conflicts-of-Interest-Policy.pdf" target="_blank" rel="noopener noreferrer" >{t('description.part67')}</a>
                                                <a href="https://www.godocm.com/wp-content/uploads/2024/01/Cookies-Policy-form.pdf" target="_blank" rel="noopener noreferrer" >{t('description.part68')}</a>
                                                <a href="https://www.godocm.com/wp-content/uploads/2024/01/Credit-Card-Disclaimer.pdf" target="_blank" rel="noopener noreferrer" >{t('description.part69')}</a>
                                                <a href="https://www.godocm.com/wp-content/uploads/2024/01/Execution-Policy.pdf" target="_blank" rel="noopener noreferrer" >{t('description.part70')}</a>
                                                <a href="https://www.godocm.com/wp-content/uploads/2024/04/Privacy-Policy.pdf" target="_blank" rel="noopener noreferrer" >{t('description.part71')}</a>
                                                <a href="https://www.godocm.com/wp-content/uploads/2024/01/Withdrawal-and-refund-policy-.pdf" target="_blank" rel="noopener noreferrer"  >{t('description.part72')}</a>
                                                <a href="https://www.godocm.com/wp-content/uploads/2024/01/Risk-Warning.pdf" target="_blank" rel="noopener noreferrer" >{t('description.part73')}</a>
                                            </li>
                                            <li>{t('description.part74')} </li>
                                            <li>{t('description.part75')} </li>
                                        </ul>
                                        <Col md={12}>
                                            <div className={`input-group mb-3 ${(i18n.language === 'de' || i18n.language === 'fa') ? 'align-items-end' : ''} `}>
                                                <label className={`d-flex align-items-center ${(i18n.language === 'de' || i18n.language === 'fa') ? '' : ''}`}>
                                                    <input
                                                        type="checkbox"
                                                        name="agreement"
                                                        style={{ marginTop: i18n.language !== ('de' || 'fa') && '7px', marginLeft: (i18n.language === 'de' || i18n.language === 'fa') && '7px' }}
                                                        checked={true}

                                                    />
                                                    <p className="mb-0 ms-3">
                                                        {
                                                            entity === 'FSA' ? t('description.part76') :
                                                                entity === 'SCA' ? t('description.part85') :
                                                                    t('description.part84')

                                                        }
                                                    </p>
                                                </label>
                                            </div>
                                        </Col>
                                    </Col>
                                </Row>
                            </div>
                        )
                        }
                        <div className="justify align-items-center d-flex flex-column flex-sm-row gap-y-2 mt-3 ">
                            {currentStep === 1 ? '' : <Button onClick={(e) => submitForm(e, 'previous')} className='primary-bg-color col buttonWidth mobile-disabled' style={{ height: '50px' }}>
                                {t('description.part51')}
                            </Button>}
                            <Button type="submit" onClick={currentStep === 4 ? (e) => submitForm(e, 'submit') : (e) => submitForm(e, 'next')} className='primary-bg-color col buttonWidth' style={{ height: '50px', background: '#26539F' }}>
                                {currentStep === 4 ? t('description.part77') : t('description.part28')}
                            </Button>
                        </div>
                        <div className="mt-3">
                            <Row md={12}>
                                <Col>
                                    <p className="text-danger">
                                        <Trans i18nKey="description.part29">
                                            <b>Exercise caution:</b> If uncertain about trading risks or uncomfortable with leveraging, it's advisable not to proceed with the application form, as trading entails the potential loss of your entire investment.
                                        </Trans>
                                    </p>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <ReCAPTCHA
                                size="invisible"
                                sitekey={process.env.REACT_APP_SITE_KEY}
                                onChange={handleRecaptcha}
                                ref={recaptchaRef}
                            />
                        </div>
                    </CustomForm>
                </>}
        </div>
    )
}